export default {
  id: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: false,
  },
  required: {
    type: Boolean,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
  },
  helpText: {
    type: String,
    required: false,
  },
  prepend: {
    type: String,
    required: false,
  },
  append: {
    type: String,
    required: false,
  },
  error: {
    type: Array,
    required: false,
    default: () => [],
  },
};
