import baseProps from "./base-input/baseProps.js";

export default {
  ...baseProps,
  placeholder: {
    type: String,
    required: false,
  },
  value: {
    type: [String, Number, Boolean],
    required: false,
  },
};
