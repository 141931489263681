<template>
  <div>
    <section class="d-flex justify-content-between heading-page">
      <NbPageTitle
        class="left-side"
        titleClass="heading-3"
        subTitleClass="body-3"
        :title="$t('boxesPage.title')"
        :subTitle="$t('boxesPage.subTitle')"
      />
      <div class="right-side">
        <div class="heading-3 mb-3">
          {{ $t("boxesPage.newBoxTemplate") }}
        </div>
        <NbButton
          class="w-100 text-left mb-2"
          icon="plus"
          data-toggle="modal"
          data-target="#modalAddBox"
          @click="resetNewBox()"
        >
          {{ $t("boxesPage.addBox") }}
        </NbButton>
      </div>
    </section>

    <section>
      <NbTablev2
        tableOf="boxesPage"
        namespace="boxes"
        ref="boxesPage"
        :hiddeSelectColumns="true"
        :allFields="allFields"
        :fields="fields"
        @reloadFields="fields = $event"
        @total="$emit('total', $event)"
      >
        <template #cell(length)="data">
          {{ standardizeValue(data.item.length)
          }}{{ measurementSystem.diameter }}
        </template>
        <template #cell(height)="data">
          {{ standardizeValue(data.item.height)
          }}{{ measurementSystem.diameter }}
        </template>
        <template #cell(width)="data">
          {{ standardizeValue(data.item.width)
          }}{{ measurementSystem.diameter }}
        </template>
        <template #cell(weight)="data">
          {{ standardizeValue(data.item.weight)
          }}{{ data.item.weight ? measurementSystem.weight : "" }}
        </template>
        <template #cell(actions)="data">
          <NbButton
            variant="tertiary"
            icon="edit"
            data-toggle="modal"
            data-target="#modalAddBox"
            @click="copyBox(data.item)"
          >
            {{ $t("edit") }}
          </NbButton>
          <NbButton
            variant="tertiary"
            icon="trash"
            data-toggle="modal"
            data-target="#modalDeleteBox"
            @click="boxToDeleteId = data.item.id"
          >
            {{ $t("delete") }}
          </NbButton>
        </template>

        <template #buttons-start>
          <div class="d-flex justify-content-between">
            <button
              class="button-secondary float-right mb-1"
              v-if="!createBoxDisplayed"
              data-toggle="modal"
              data-target="#modalAddBox"
            >
              {{ $t("boxesPage.addBox") }}
            </button>
          </div>
        </template>
      </NbTablev2>
    </section>

    <!-- modals -->
    <NbModal id="modalAddBox" modalConfig="modal-dialog-centered" width="500px">
      <template v-slot:header>
        <div class="heading-4">
          {{
            new_box.id
              ? $t("boxesPage.updateBox")
              : $t("boxesPage.createNewBox")
          }}
        </div>
      </template>
      <template v-slot:body>
        <div class="d-flex justify-content-between flex-wrap">
          <NbTextInput
            :required="true"
            id="name"
            class="w-48-5"
            :name="$t('name')"
            :error="validationErrors['name']"
            v-model="new_box.name"
          />
          <NbTextInput
            :required="false"
            id="weight"
            class="w-48-5"
            type="number"
            :append="measurementSystem.weight"
            :name="`${$t('weight')} (${measurementSystem.weight})`"
            :decimals="3"
            step="0.001"
            :placeholder="`0.000 ${measurementSystem.weight}`"
            :error="validationErrors['weight']"
            v-model="new_box.weight"
          />
          <NbTextInput
            :required="true"
            id="height"
            class="w-32-5"
            type="number"
            :append="measurementSystem.diameter"
            :name="`${$t('height')} (${measurementSystem.diameter})`"
            :decimals="2"
            step="0.01"
            :placeholder="`0.00 ${measurementSystem.diameter}`"
            :error="validationErrors['height']"
            v-model="new_box.height"
          />
          <NbTextInput
            :required="true"
            id="width"
            class="w-32-5"
            type="number"
            :append="measurementSystem.diameter"
            :name="`${$t('width')} (${measurementSystem.diameter})`"
            :decimals="2"
            step="0.01"
            :placeholder="`0.00 ${measurementSystem.diameter}`"
            :error="validationErrors['width']"
            v-model="new_box.width"
          />
          <NbTextInput
            :required="true"
            id="length"
            class="w-32-5"
            type="number"
            :append="measurementSystem.diameter"
            :name="`${$t('length')} (${measurementSystem.diameter})`"
            :decimals="2"
            step="0.01"
            :placeholder="`0.00 ${measurementSystem.diameter}`"
            :error="validationErrors['length'] || []"
            v-model="new_box.length"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton @click="sendBoxForm()">
            {{ $t("save") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <NbModal
      id="modalDeleteBox"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("delete") }}
        </div>
      </template>
      <template v-slot:body> {{ $t("confirmDelete", { val: "" }) }}? </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton variant="secondary" data-dismiss="modal">
            {{ $t("cancel") }}
          </NbButton>
          <NbButton @click="removeBox(boxToDeleteId)">
            {{ $t("delete") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NProgress from "nprogress";

import BoxService from "@/services/BoxService";

const boxService = new BoxService();

export default {
  name: "NbBoxes",
  components: {
    NbTablev2,
    NbPageTitle,
    NbButton,
    NbModal,
    NbTextInput,
  },
  props: {
    measurementSystem: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      loaded: false,
      new_box: {
        id: null,
        name: null,
        weight: null,
        height: null,
        width: null,
        length: null,
      },
      validationErrors: [],
      fields: [],
      boxToDeleteId: null,
      boxes: [],
    };
  },
  created() {},
  methods: {
    resetNewBox() {
      this.new_box = {
        id: null,
        name: null,
        weight: null,
        height: null,
        width: null,
        length: null,
      };
      this.validationErrors = [];
    },
    copyBox(data) {
      if (data.id) {
        this.new_box = data;
        this.validationErrors = [];
      }
    },
    removeBox(boxId) {
      boxService.removeBox(boxId).then(() => {
        this.$refs.boxesPage.getData();
        this.$helpers.closeModal("modalDeleteBox");
      });
    },
    sendBoxForm() {
      if (this.new_box.id) {
        this.editBox();
        return;
      }
      this.createBox();
    },
    editBox() {
      boxService
        .updateBox(this.new_box.id, this.new_box)
        .then(() => {
          this.$refs.boxesPage.getData();
          this.resetNewBox();
          this.$helpers.closeModal("modalAddBox");
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }
          NProgress.done();
        });
    },
    createBox() {
      boxService
        .createBox(this.new_box)
        .then(() => {
          this.$refs.boxesPage.getData();
          this.resetNewBox();
          this.$helpers.closeModal("modalAddBox");
        })
        .catch((error) => {
          if (
            error.hasOwnProperty("response") &&
            error.response.hasOwnProperty("data") &&
            error.response.data.hasOwnProperty("messages")
          ) {
            this.validationErrors = error.response.data.messages[0];
          }
          NProgress.done();
        });
    },
    standardizeValue(val) {
      let parsed = " - ";
      if (val) {
        parsed = parseFloat(val).toFixed(2);
      }
      return parsed;
    },
  },
  computed: {
    allFields() {
      return [
        { key: "name", label: this.$t("name"), sortable: true },
        {
          key: "weight",
          label: `${this.$t("emptyWeight")} (${this.measurementSystem.weight})`,
          sortable: true,
        },
        {
          key: "length",
          label: `${this.$t("length")} (${this.measurementSystem.diameter})`,
          sortable: true,
        },
        {
          key: "height",
          label: `${this.$t("height")} (${this.measurementSystem.diameter})`,
          sortable: true,
        },
        {
          key: "width",
          label: `${this.$t("width")} (${this.measurementSystem.diameter})`,
          sortable: true,
        },
        {
          key: "actions",
          isCustomizable: false,
          label: this.$t("quickActions"),
          class: "right--20 right-sticky",
        },
      ];
    },
    filterOptions() {
      return [{ key: "name", label: this.$t("orderNumber") }];
    },
  },
};
</script>

<style scoped>
.heading-page .left-side {
  max-width: 32rem;
  margin-right: 0.75rem;
  margin-bottom: 2.8rem;
}
.heading-page .right-side {
  width: 15.9rem;
  margin-bottom: 2.8rem;
}
</style>
