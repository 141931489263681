import HttpService from "./HttpService";

export default class BoxService {
  constructor() {
    this.httpService = HttpService;
  }

  getBoxes(filters = {}) {
    return this.httpService.get("/v1/boxes", {
      params: filters,
    });
  }

  getBox(id) {
    return this.httpService.get("/v1/boxes/" + id);
  }

  updateBox(id, data) {
    return this.httpService.put("/v1/boxes/" + id, data);
  }

  removeBox(id) {
    return this.httpService.delete("/v1/boxes/" + id);
  }

  createBox(data) {
    return this.httpService.post("/v1/boxes", data);
  }
}
