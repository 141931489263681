import HttpService from "./HttpService";

export default class ProductService {
  constructor() {
    this.httpService = HttpService;
  }

  getProducts(filters = {}) {
    return this.httpService.get("/v1/products", {
      params: filters,
    });
  }

  getProduct(id) {
    return this.httpService.get("/v1/product/" + id);
  }

  fetchProduct(value, target) {
    return this.httpService.get(`/v1/products-by-sku?${target}=${value}`);
  }
  getProductsAll() {
    return this.httpService.get(`/v1/all_products`);
  }

  updateProduct(id, data) {
    for (const key in data) {
      if (typeof data[key] === "string" && data[key].trim() === "") {
        data[key] = null;
      }
    }
    return this.httpService.put("/v1/product/" + id, data);
  }

  removeProduct(id) {
    return this.httpService.delete("/v1/product/" + id);
  }

  removeMassiveProduct(data) {
    return this.httpService.delete("/v1/products/massive_destroy/" + data);
  }

  createProduct(data) {
    for (const key in data) {
      if (typeof data[key] === "string" && data[key].trim() === "") {
        data[key] = null;
      }
    }
    return this.httpService.post("/v1/product", data);
  }

  downloadProducts(ids = []) {
    return this.httpService.get("/v1/products/download", {
      params: { ids: ids },
    });
  }

  uploadProducts(data) {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return this.httpService.post("/v1/products/import", data, config);
  }

  validImport(data) {
    return this.httpService.post("/v1/products/confirm-import", {
      products: data,
    });
  }
}
