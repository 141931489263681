import HttpService from "./HttpService";

export default class CountryService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get countries
   *
   * @param filters
   * @returns {Promise}
   */
  getCountries(filters = {}) {
    return this.httpService.get("/v1/countries", {
      params: filters,
    });
  }

  getCountries_for_quotes(filters = {}) {
    return this.httpService.get("/v1/countries_quote", {
      params: filters,
    });
  }
}
