<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <NbPageTitle
      :title="$t('boxesProductsPage.title')"
      :subTitle="$t('boxesProductsPage.subTitle')"
    />

    <section class="w-100 pl-3">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <NbProducts
        :measurementSystem="measurementSystem"
        :class="currentTable == 'productModels' ? '' : 'display-none'"
        @total="totalProducts = $event"
      />

      <NbBoxes
        :measurementSystem="measurementSystem"
        :class="currentTable == 'boxModels' ? '' : 'display-none'"
        @total="totalBoxes = $event"
      />
    </section>

    <NbFooter
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />
  </div>
</template>

<script>
import LoadingPage from "@/components/loadings/LoadingPage.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbBoxes from "@/views/settings/NbBoxes.vue";
import NbProducts from "@/views/settings/NbProducts.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";

import UsersService from "@/services/UsersService";
const usersService = new UsersService();

export default {
  name: "BoxesProducts",
  components: {
    LoadingPage,
    NbTabs,
    NbPageTitle,
    NbBoxes,
    NbProducts,
    NbFooter,
  },
  data: () => {
    return {
      loaded: false,

      //allFields: [],
      measurementSystem: {
        diameter: "cm",
        weight: "kg",
      },
      currentTable: "",
      totalBoxes: 0,
      totalProducts: 0,
    };
  },
  created() {
    this.setMeasurementSystem();
    setTimeout(() => {
      this.loaded = false;
    }, 500);
    this.currentTable = "productModels";
  },
  methods: {
    setMeasurementSystem() {
      usersService.getUserMeasurementSystem().then(({ data }) => {
        this.measurementSystem.diameter = this.currentMeasurementMetric(
          data.data
        );
        this.measurementSystem.weight = this.currentWeightMetric(data.data);
      });
    },
    switchTab(tab) {
      this.currentTable = tab;
    },
    currentWeightMetric(system) {
      if (system === "metric") {
        return "kg";
      }
      return "lb";
    },
    currentMeasurementMetric(system) {
      if (system === "metric") {
        return "cm";
      }
      return "in";
    },
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "productModels",
          label: this.$t("boxesProductsPage.productModels"),
          found: this.totalProducts || 0,
          current: this.currentTable,
        },
        {
          id: "boxModels",
          label: this.$t("boxesProductsPage.boxModels"),
          found: this.totalBoxes || 0,
          current: this.currentTable,
        },
      ];
    },
  },
};
</script>
